




















import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { MNotification } from '@/models/MNotification';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'NotifiactionListShow',

  components: {
    BaseGridTable,
    LayoutPage,
  },

  data() {
    return {
      gridActionList: [],

      gridColumnDefinition: [
        {
          headerName: this.$tc('notification.property.message'),
          field: 'message',
        },
        {
          headerName: this.$t('notification.property.new'),
          field: 'new',
        },
      ],
    };
  },

  computed: {
    notificationList(): Array<MNotification> {
      return MNotification.all();
    },

    gridRowData(): Array<any> {
      return this.notificationList.map(
        notification => notification.asListData,
      );
    },
  },

  methods: {
    edit(Id: string): void {
      const notification = MNotification.find(Id);

      this.$router.push({
        path: notification.Path,
      });
    },
  },
});
